exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-application-development-js": () => import("./../../../src/pages/services/mobile-application-development.js" /* webpackChunkName: "component---src-pages-services-mobile-application-development-js" */),
  "component---src-pages-services-web-application-development-js": () => import("./../../../src/pages/services/web-application-development.js" /* webpackChunkName: "component---src-pages-services-web-application-development-js" */),
  "component---src-pages-services-website-revamp-apparel-decorators-jsx": () => import("./../../../src/pages/services/website-revamp-apparel-decorators.jsx" /* webpackChunkName: "component---src-pages-services-website-revamp-apparel-decorators-jsx" */),
  "component---src-templates-portfolio-temp-js": () => import("./../../../src/templates/portfolio-temp.js" /* webpackChunkName: "component---src-templates-portfolio-temp-js" */)
}

